<template>
  <article :class="['contenedor-hijo', {'z-index-101': opcionesDeImpresion.mostrarModal}]">
    <div class="container">
      <div class="row justify-content-center justify-content-md-between mb-3 mr-0 ml-2 ml-sm-0">
        <mdb-btn
          flat
          dark-waves
          icon="arrow-left"
          type="button"
          title="Regresar"
          @click="$router.go(-1)"
        >
          Regresar
        </mdb-btn>
        <mdb-btn
          dark-waves
          flat
          icon="dolly"
          class="mx-0 my-1 px-2"
          :disabled="desCargando"
          @click="verificacionDes()"
        >
          Devolver
        </mdb-btn>
        <mdb-dropdown class="text-center text-md-left">
          <mdb-dropdown-toggle
            slot="toggle"
            color="flat"
            class="mx-0 my-1 px-2"
          >
            <font-awesome-icon icon="print" />
            Imprimir
          </mdb-dropdown-toggle>
          <mdb-dropdown-menu>
            <mdb-dropdown-item @click="imprimir('OrdenDeSalida')">
              <font-awesome-icon icon="file-pdf" />
                Orden de salida
            </mdb-dropdown-item>
            <mdb-dropdown-item @click="imprimir('NotaDeEntrega')">
              <font-awesome-icon icon="file-pdf" />
                Nota de entrega
            </mdb-dropdown-item>
            <mdb-dropdown-item @click="imprimirTablaDespacho()">
              <font-awesome-icon icon="file-pdf" />
                Verificación cliente
            </mdb-dropdown-item>
          </mdb-dropdown-menu>
        </mdb-dropdown>
      </div>
      <div
        v-if="cargando"
        class="mensaje-no-items py-3"
      >
        <LoaderKel />
        <p class="texto mt-2">
          Cargando
        </p>
      </div>
      <div v-else>
        <div class="encabezado-detalles">
          <header class="h4-responsive text-center pt-3">Detalles del Despacho</header>
          <div class="row mx-0 mt-2">
            <div class="col-12 col-md-4">
              <span class="small-block font-titulo">Transportista</span>
              <p class="text-capitalize">
                <span class="text-muted">
                  {{ $route.params.id }}
                </span>
                {{ despacho.desdet?.transportista?.nom_per }} {{ despacho.desdet?.transportista?.ape_per }}
              </p>
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <span class="small-block font-titulo">Fecha de salida</span>
              <p :class="[{'text-capitalize': despacho.desdet?.fechaSalida}]">
                {{ despacho.desdet?.fechaSalida || 'Aún sin asignar'}}
              </p>
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <span class="small-block font-titulo">Fecha de retorno</span>
              <p :class="[{'text-capitalize': despacho.desdet?.fechaRetorno}]">
                {{ despacho.desdet?.fechaRetorno || 'Aún sin asignar'}}
              </p>
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <span class="small-block font-titulo">Estatus</span>
              <p :class="['text-capitalize', asignarColorEstado(despacho.desdet?.estatus)]">{{ despacho.desdet?.estatus }}</p>
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <span class="small-block font-titulo">Zonas</span>
              <p class="text-capitalize">
                <span
                  v-for="(zona, z) in despacho.zonas"
                  :key="z"
                  class="auto-coma"
                >
                  {{ zona.est_zon }}
                </span>
              </p>
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <span class="small-block font-titulo">Peso</span>
              <p class="text-capitalize">{{ Number(despacho.desdet?.peso).toFixed(2) }} kg</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <OpcionesImpresion
      :mostrar-modal="opcionesDeImpresion.mostrarModal"
      :codigoDeImpresion="opcionesDeImpresion.codigoDeImpresion"
      :nombre-documento="opcionesDeImpresion.nombreDocumento"
      :orientacionSugerida="'landscape'"
      @cerrar="opcionesDeImpresion.mostrarModal = $event"
    />
    <ConfirmacionEliminar
      :boton-deshabilitado="botonDeshabilitado"
      :mostrar-modal="modalConfirmacionEliminar"
      :mensaje="`El despacho ${$route.params.id} pasará a estatus Cargando`"
      textoBotonConfirmar="Devolver"
      @cerrar="modalConfirmacionEliminar = false"
      @confirmar="devolverDespacho"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </article>
</template>

<script>
import {
  mdbBtn,
  mdbDropdown,
  mdbDropdownItem,
  mdbDropdownMenu,
  mdbDropdownToggle
} from 'mdbvue'

import { apiPost } from '@/funciones/api.js'
import { asignarColorEstado } from '@/funciones/funciones.js'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import ConfirmacionEliminar from '@/components/ConfirmacionEliminar.vue'
import OpcionesImpresion from '@/components/OpcionesImpresion.vue'
import LoaderKel from '@/components/LoaderKel.vue'
import { squareRegular } from '@/imgsBase64/imgsBase64.js'
import { VerificacionClienteTransportista } from '@/funciones/formatosImpresion.js'

export default {
  name: 'Despacho',
  components: {
    mdbBtn,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    AlertaMensaje,
    ConfirmacionEliminar,
    OpcionesImpresion,
    LoaderKel
  },
  data () {
    return {
      cargando: false,
      asignarColorEstado,
      alertaMensaje: { contenido: '' },
      modalConfirmacionEliminar: false,
      botonDeshabilitado: false,
      desCargando: false,
      despacho: {},
      zonas: {},
      opcionesDeImpresion: {
        codigoDeImpresion: [],
        mostrarModal: false,
        nombreDocumento: ''
      }
    }
  },
  mounted () {
    this.cargaInicial()
  },
  methods: {
    async cargaInicial () {
      this.cargando = true
      this.despacho = {}
      this.zonas = {}
      try {
        const zonasRes = await apiPost({ s: 'zonasMv' }, '')
        this.zonas = this.enlistarZonas(zonasRes.data.zon)

        const despachoRes = await apiPost({ s: 'desEditallesMv' }, 'des=' + this.$route.params.id)
        if (despachoRes.data) {
          // Detalles
          despachoRes.data.desdet.forEach((p) => {
            this.despacho.desdet = {
              fechaSalida: p.fec_des,
              fechaRetorno: p.fec_ret_des,
              observaciones: p.obs_des,
              estatus: p.est_des,
              peso: p.pes_des,
              vigilante: {
                id_per: p.v_id_per,
                nom_per: p.v_nom_per,
                ape_per: p.v_ape_per
              },
              montacarguista: {
                id_per: p.m_id_per,
                nom_per: p.m_nom_per,
                ape_per: p.m_ape_per
              },
              almacenista: {
                id_per: p.a_id_per,
                nom_per: p.a_nom_per,
                ape_per: p.a_ape_per
              },
              transportista: {
                id_per: p.t_id_per,
                nom_per: p.t_nom_per,
                ape_per: p.t_ape_per
              }
            }
          })
          // Nuevos
          this.despacho.nuevos = this.enlistarNuevos(despachoRes.data)
          // Zonas
          const zonas = {}
          despachoRes.data.cliv.forEach(c => {
            if (!zonas[this.zonas[c.id_zon_suc].est_zon]) {
              zonas[this.zonas[c.id_zon_suc].est_zon] = {
                est_zon: this.zonas[c.id_zon_suc].est_zon.toLowerCase(),
                clientes: []
              }
            }
            // Clientes
            zonas[this.zonas[c.id_zon_suc].est_zon].clientes.push({
              id_suc: c.id_suc,
              nom_suc: c.nom_suc,
              sada_suc: c.sada_suc,
              nom_emp: c.nom_emp,
              rif_emp: c.rif_emp,
              nom_tdoc: c.nom_tdoc,
              nom_cp: c.nom_cp,
              nom_tpre: c.nom_tpre,
              id_zon_suc: c.id_zon_suc,
              nom_tcl: c.nom_tcl,
              dsc_ped: Number(c.dsc_ped),
              dir_suc: c.dir_suc,
              tel_suc: c.tel_suc,
              obs_ped: c.obs_ped
            })
          })
          this.despacho.zonas = zonas

          this.desCargando = this.despacho.desdet?.estatus === 'Cargando'
        }
      } catch (error) {
        this.alertaMensaje = {
          contenido: 'Ocurrió un problema obteniendo los detalles del despacho',
          tipo: 'error'
        }
      }
      this.cargando = false
    },
    enlistarZonas (zonas) {
      if (!zonas) return []
      const zona = {}
      zonas.forEach(z => {
        zona[z.id_zon] = z
      })
      return zona
    },
    enlistarNuevos (des) {
      const nuevos = {
        porPed: {},
        porSuc: {}
      }
      if (!des || !des.nuevos) return nuevos
      des.nuevos.forEach((n) => {
        nuevos.porPed[n.id_ped] = true
        nuevos.porSuc[n.id_suc_ped] = true
      })
      return nuevos
    },
    async imprimir (tipo) {
      try {
        await this.abrirVentana(tipo)
      } catch (error) {
        this.alertaMensaje = {
          contenido: 'Ocurrió un problema obteniendo los detalles del despacho',
          tipo: 'error'
        }
      }
    },
    abrirVentana (impresion) {
      if (
        !this.despacho.desdet.vigilante.id_per ||
        !this.despacho.desdet.fechaSalida ||
        !this.despacho.desdet.fechaRetorno ||
        !this.despacho.desdet.montacarguista.id_per ||
        !this.despacho.desdet.almacenista.id_per
      ) {
        this.alertaMensaje = {
          contenido: 'Faltan datos para la impresión, por favor, ve a distribución sección de despachos',
          tipo: 'error'
        }
        return
      }
      const routeData = this.$router.resolve({ name: impresion, params: { id: this.$route.params.id } })
      window.open(routeData.href, '_blank')
    },
    verificacionDes () {
      if (!this.desCargando) {
        const [FECHA] = this.despacho.desdet?.fechaSalida.slice(0, 16).split(' ')
        const com = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)) + '-' + ('0' + (new Date().getDate()))
        const fecha1 = new Date(FECHA)
        const fecha2 = new Date(com)
        const diferencia = fecha2.getTime() - fecha1.getTime()
        const diasDeDiferencia = diferencia / 1000 / 60 / 60 / 24
        if (diasDeDiferencia <= 2) {
          let obs = this.despacho.desdet.observaciones
          obs = obs.includes('null') ? 'Despacho devuelto' : obs + '. Despacho devuelto'
          this.devolverDespacho(obs)
          this.modalConfirmacionEliminar = true
        } else {
          this.alertaMensaje = {
            contenido: 'Han transcurrido más de dos días desde que se despachó',
            tipo: 'advertencia'
          }
        }
      } else {
        this.alertaMensaje = {
          contenido: 'El despacho ya se encuentra en estado "Cargando"',
          tipo: 'advertencia'
        }
      }
    },
    devolverDespacho (obs) {
      this.botonDeshabilitado = true
      apiPost({ s: 'desDevolverMv' }, 'id=' + this.$route.params.id + '&obs= ' + obs)
        .then((res) => {
          if (res.data.r) {
            this.alertaMensaje = {
              contenido: 'Operación exitosa',
              tipo: 'correcto'
            }
            this.modalConfirmacionEliminar = false
            this.botonDeshabilitado = false
            this.cargaInicial()
          }
        }).catch(() => {
          this.alertaMensaje = {
            contenido: 'Ha ocurrido un problema devolviendo el despacho',
            tipo: 'error'
          }
        })
    },
    cantidadClientes (zon) {
      let totalCli = 0
      for (const z in zon) {
        totalCli += zon[z].clientes.length
      }
      return totalCli
    },
    imprimirTablaDespacho () {
      const TRANSPORTISTA = `${this.despacho.desdet.transportista.nom_per} ${this.despacho.desdet.transportista.ape_per}`
      // Estableciendo la estructura para la tabla de clientes
      const CLIENTES = [
        // Fila de encabezados
        [
          { text: '' },
          {
            stack: [
              'CLIENTE - SUCURSAL',
              {
                text: 'RIF | SADA',
                fontSize: 7
              },
              {
                text: 'TELÉFONO',
                fontSize: 7
              }
            ]
          },
          {
            stack: [
              'TIPO DE CLIENTE - TIPO DE PRECIO',
              {
                text: 'CONDICIÓN DE PAGO',
                fontSize: 7
              }
            ]
          },
          { text: 'PROMOCIÓN' },
          { text: 'DIRECCIÓN' },
          { text: 'OBSERVACIONES' }
        ]
      ]
      let zonas = ''
      let coma = ''
      // Estableciendo las zonas a string
      for (const idZon in this.despacho.zonas) {
        if (Object.hasOwnProperty.call(this.despacho.zonas, idZon)) {
          const zona = this.despacho.zonas[idZon]
          zonas += coma + zona.est_zon
          coma = ', '
        }
      }
      zonas += '.'

      for (const zona in this.despacho.zonas) {
        if (Object.hasOwnProperty.call(this.despacho.zonas, zona)) {
          const clientes = this.despacho.zonas[zona].clientes
          CLIENTES.push([
            {
              text: this.despacho.zonas[zona].est_zon.toUpperCase(),
              colSpan: 6,
              alignment: 'center',
              bold: true
            }
          ])

          clientes.forEach(cliente => {
            CLIENTES.push([
              { image: squareRegular },
              {
                stack: [
                  `${cliente.nom_emp} - ${cliente.nom_suc}`,
                  {
                    text: `RIF: ${cliente.rif_emp} ${cliente.sada_suc ? `| SADA: ${cliente.sada_suc}` : ''}`,
                    fontSize: 7
                  },
                  {
                    text: cliente.tel_suc ? `+${cliente.tel_suc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, '$1 $2 $3 $4')}` : '',
                    fontSize: 7
                  }
                ]
              },
              {
                stack: [
                  `${cliente.nom_tcl} - ${cliente.nom_tpre}`,
                  {
                    text: cliente.nom_cp,
                    fontSize: 8
                  }
                ]
              },
              {
                text: `${cliente.dsc_ped}%`,
                fontSize: 8
              },
              { text: cliente.dir_suc },
              {
                stack: [
                  {
                    text: `${this.despacho.nuevos.porSuc[cliente.id_suc] ? 'CLIENTE NUEVO' : ''}`,
                    fontSize: 8,
                    bold: true
                  },
                  cliente.obs_ped
                ]
              }
            ])
          })
        }
      }

      const { nombre, formato } = VerificacionClienteTransportista(TRANSPORTISTA, zonas, CLIENTES, this.cantidadClientes(this.despacho.zonas))

      this.opcionesDeImpresion = {
        codigoDeImpresion: formato,
        nombreDocumento: nombre,
        mostrarModal: true
      }
    }
  }
}
</script>

<style lang="scss">
.z-index-101 {
  z-index: 101 !important;
}
</style>
